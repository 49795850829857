<template>
  <div class="home">
    404
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>
