import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    require('bootstrap');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */


window.Pusher = require('pusher-js');

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import MarqueeText from 'vue-marquee-text-component'
import DigitalClock from "vue-digital-clock";


const moment = require('moment')
require('moment/locale/es')
window.moment = moment


// Install BootstrapVue
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(require('vue-moment'), {
    moment
})

Vue.component('marquee-text', MarqueeText)
Vue.component('digital-clock', DigitalClock)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
